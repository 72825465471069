// a list of Drupal nodes (any type)
class NodeList {
    constructor(list) {
        
        // this.state = {
        //     list: null
        // };

        this.processList(list)
        console.log('#45rwfscs): ', this.list)
    }

    processList (list) {
        console.log('in NodeList constructor with list (#5fwsdf): ', list);

        if (!list || list.length == 0) {
            this.list = []
            console.log('empty ndelist #23sdf3: ', this.list)
            return true
        }

        var keyedList = [];
        list.forEach( function( node ) {
            // console.log('node (#5dfsdf): ', node)
            keyedList[ node.id ] = node;
        });

        this.list = keyedList;
        console.log ('list processed (#zdfsdg): ', this.list, 'length: ', this.length())
    }
    
    length() {
        return Object.keys(this.list).length        // don't use normal .length() as it's an associative array / obj
    }

    removeItem(item) {
        const id = item.id

        console.log('#dsfdsf: ', this.list[id])
        delete this.list[id]

        return true
    }

    getNodeById (id) {
        if (!this.list[id]) {
            throw new Error ('List node with id: ' + id + ' could not be found.')
        }

        if (!this.list[id]) {
            return null
        }
        return this.list[id]
    }

    getList () {
        return this.list
    }
  }

export default NodeList