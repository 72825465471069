import axios from 'axios';
import NodeList from '../../classes/NodeList'

const GetSavedItemsByUserId = (savedItems, setSavedItems, forceLoad = false) => {
    // check if savedItems has already been populated (no need to re-run the request)
    if (savedItems == null || forceLoad) {

        var url = 'https://dev-tuckel.pantheonsite.io/jsonapi/node/saved_item?sort=-created&include=field_item_target'

        axios.get(url)
        .then((response) => {
            // var SIlist = response.data.data
            
            console.log(response.data, '#23rwqe23');
            console.log(response.data.data, '#23rwqe');
            console.log(response.status);
            console.log(response.statusText);
            console.log(response.headers);
            console.log(response.config);

            // create a list of "included" "target item" nodes

            const targetNodesList = new NodeList(response.data.included)
            const SIlist = new NodeList(response.data.data)
            // const targetNodesList = new NodeList()
            console.log ('target nodeList (#wewe09j): ', targetNodesList)
            console.log ('SIlist (#wewe09j): ', SIlist)

            savedItems = { SIlist: SIlist, included: targetNodesList }
            setSavedItems(savedItems)
            console.log( 'savedItems (#4v8wefjn11): ', savedItems)
        });
    }
};

export default GetSavedItemsByUserId;