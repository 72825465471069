import { messageService } from '../../services/message.service'

// any alerts that are passed in via the state of: navigate() will be processed and displayed to the user.
const handleAlerts = (location) => {
    if (location.state) {
        if (location.state.alert) {
            console.log('[handleAlerts] state: ', location.state)
            console.log('[handleAlerts] alert: ' + location.state.alert)
            const alert = location.state.alert
    
            // const msg = 'You have been successfully logged out.'
            messageService.sendMessageAutoClose(alert.msg, alert.type);
        } else {
            console.log('[handleAlerts] no alerts to display 1.', location.state)
        }
    } else {
        console.log('[handleAlerts] no alerts to display 2.', location.state)
    }
    
    return null
  }

export default handleAlerts