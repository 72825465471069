import styled from "styled-components"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';

import { colors } from 'styles/GlobalStyles'

export const CustomContainer = styled(Container)`

  width: "100%";
  /* border: 10px solid #333; */

  padding-right: 0px;
  padding-left: 0px;

  /* h3 { */
    /* / border: 10px solid #0f0; */
    /* margin-bottom:  0px !important; */
    /* margin-top:     10px !important; */
  /* } */

  /* p {
    margin-top: 0px;
  } */

  .row {
    margin: 0px;
  }

  /* buttons need no padding at the top for alignment */
  div.buttonHolder {
    padding-top: 0px !important;
  }

  Button {
    /* border: 10px solid #00f !important; */
    margin-bottom: 3px;
  }

  /* ItinDetailTable: "View Hightlights" button styling */
  .itinHighlights {
    background-color: ${colors.legendHighlight} !important;
    border:           1px solid ${colors.legendHighlight} !important;
    color:            #111
  }

  .itinHighlights:hover {
    /* background-color: ${colors.legendHighlight} !important; */
    opacity: .65 !important;
  }

  .itinHighlights:active {
    /* background-color: ${colors.legendHighlight} !important; */
    opacity: 1 !important;
  }

  
  /* ItinDetailTable: "View Photographs" button styling */
  .itinPhotographs {
    background-color: ${colors.legendPhotograph} !important;
    border:           1px solid ${colors.legendPhotograph} !important;
    color:            #111
  }

  .itinPhotographs:hover {
    /* background-color: ${colors.legendPhotograph} !important; */
    opacity: .65 !important;
  }

  .itinPhotographs:active {
    /* background-color: ${colors.legendPhotograph} !important; */
    opacity: 1 !important;
  }

  
  /* ItinDetailTable: "View Activities" button styling */
  .itinPlan {
    background-color: ${colors.legendActivity} !important;
    border:           1px solid ${colors.legendActivity} !important;
    color:            #111
  }

  .itinPlan:hover {
    /* background-color: #f900ff !important; */
    opacity: .65 !important;
  }

  .itinPlan:active {
    /* background-color: #f900ff !important; */
    /* opacity: 1 !important; */
    color:            #000;
  }

  /* ActiDetailTable: "View activity plan" button styling */
  .actiPlan {
    background-color: ${colors.legendPhotograph} !important;
    border:           1px solid ${colors.legendPhotograph} !important;
    color:            #111
  }

  .actiPlan:hover {
    /* background-color: #f900ff !important; */
    opacity: .65 !important;
  }

  .actiPlan:active {
    /* background-color: #f900ff !important; */
    /* opacity: 1 !important; */
    color:            #000;
  }

  // used for Cols
  .row > div {
    /* border: 1px solid #0f0; */
    padding: 2px 7px;
  }

  .noPadding {
    padding: 0px
  }

  h3 {
      /* color: #0ff; */
      margin: 5px 0px;
    }

  .deemphasized {
    color: #999;
    font-size: .9rem;
  }
`