import React, { useState, useContext } from 'react'
import { CurrentUserContext } from '../context/CurrentUserProvider'
import axios from 'axios';

// get back a function that makes a call to a url via Axios
// it handles things like getting the csrfToken from memory and logging.
export const useAxiosCall = () => {
    // get csrfToken
    const { csrfToken } = useContext(CurrentUserContext)
    console.log('[useAxiosCall] csrfToken: ', csrfToken)
    
    // const [savedItems, setSavedItems] = useState(null);

    // return a function as the csrfToken will be lexically scoped in
    // note: remember to use JSON.stringify(yourJson) on json if using payload.
    // note: customPassThroughPayload allows additional information to be passed into the onSuccess or onFailure callbacks (as an alternative to the usual approach of using lexical scoping)
    const axiosCall = (url, method, payload, onSuccess, onFailure, customHeaders = {}, customPassThroughPayload = {}) => {
        // console.log('[useAxiosCall -> axiosCall function] csrfToken: ', csrfToken)
        console.log('[useAxiosCall] method: ', method)
        console.log('[useAxiosCall] url: ', url)
        try {
            console.log('[useAxiosCall] payload (deserialized): ', JSON.parse(payload))
        } catch(e) {
            // not a json object
            console.log('[useAxiosCall] payload: ', payload)
        }
        

        const standardHeaders = {
            "X-CSRF-Token": csrfToken,
            'Content-Type': 'application/vnd.api+json',
        }

        const headers = {
            ...standardHeaders,
            ...customHeaders
        }

        // console.log('merged/final headers #gg5: ', headers)

        var callConfig = {
            method: method,
            url: url,
            headers: headers,
            withCredentials: true
        }

        console.log('[useAxiosCall] callConfig #gg5: ', callConfig)

        if (payload) {
            // callConfig.data = JSON.stringify(payload)
            callConfig.data = payload
        }

        axios(callConfig)
        .then((response) => {
            console.log('[axiosCall] success response: ', response);
            if(onSuccess) {
                onSuccess(response, customPassThroughPayload)
            }

        }).catch(e => {
            console.log('==== AXIOS ERROR: "' + e + '"')
            console.log('[axiosCall] response -> error: ',  e);
            
            if (onFailure) {
                onFailure(e, customPassThroughPayload)
            }
        });

    }

    return axiosCall
}