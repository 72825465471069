import React, { useContext }  from "react";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { Link } from "gatsby"

const LoginChecker = ({ children, roleRequired }) => {
    // todo: implement: roleRequired

    const { isLoggedIn } = useContext(CurrentUserContext)
    
    if (isLoggedIn()) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            <main>
                You are not not logged in. Please login to view this page.
                
                <br /><br />[ Click here to login: <Link to="/login">login</Link> ]
            </main>
        )
    }
    
}

export default LoginChecker