import React, { useEffect } from "react"

import Layout from "components/Layout"
import SEO from "components/SEO"
import SavedItemsList from "../components/SavedItemsList"

import handleAlerts from '../functions/handleAlerts'

import LoginChecker from '../components/LoginChecker'

const Page = ({ location }) => {
  useEffect(() => {handleAlerts(location)}, []);
  
  return (
  <Layout>
    <LoginChecker>
      <SEO title="My Bucktlist:" />
      <main>
        <h2>My Bucktlist:</h2>
        Below are the items you have saved to your Bucktlist.
        
        <br /><br />
        <SavedItemsList></SavedItemsList>
      </main>
    </LoginChecker>
  </Layout>
)}

export default Page