// Accepts a node, returns it's type as "location', 'activity', itinerary' etc.
const getDrupalNodeTypeOrError = (node) => {
    console.log ("node (#2ewfdSDF): ", node)
    
    // return 'fail123'
    if (!node.__typename) {

        // if no __typename, it' because it's come from axios. type "type" instead.
        if (node.data) {
            // throw new Error ('this code is used! (#asdf)')
            type = node.data.type.replace('node--', '')
        } else {
            if (node.type) {
                type = node.type.replace('node--', '')
            } else {
                throw new Error ('node does not have a __typename or type value. Please configure this in the graphQL query.')
            }
        }
    } else {
        var type = node.__typename.replace('node__', '');
    }
    
    // console.log ('nodeType: (#imc99) -' + type)


    return type
}

export default getDrupalNodeTypeOrError