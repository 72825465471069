import axios from 'axios';
import { object } from 'prop-types';

// ?
const DeleteSavedItem = (savedItem, axiosCall, onSuccess = null, onFailure = null) => {
    console.log('savedItem #4dwre: ', savedItem,  ', axiosCall: ', axiosCall)
    const drupal_id = savedItem.id
    var url = 'https://dev-tuckel.pantheonsite.io/jsonapi/node/saved_item/' + drupal_id

    console.log('attempting delete of saved item (#4sdfwerDel): ', drupal_id)

    const method = 'delete'
    axiosCall (url, method, null, onSuccess, onFailure)
};
 
export default DeleteSavedItem;