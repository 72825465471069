import React, { useState } from "react"
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import { CustomContainer } from "styles/CustomContainer.styles"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment'

import GetSavedItemsByUserId from "../../functions/JSONAPI_Drupal/GetSavedItemsByUserId";
import TypeMarker from "../TypeMarker";
import DeleteSavedItem from "../../functions/JSONAPI_Drupal/DeleteSavedItem";
import getDrupalNodeTypeOrError from "../../functions/Drupal/getDrupalNodeTypeOrError";
import getSlug from "../../functions/getSlug";
import { useAxiosCall } from '../../hooks/useAxiosCall'

import { messageService } from '../../services/message.service'

// const deleteAndRefresh

// Display a list of the users saved items, with basic information and a delete link
const SavedItemsList = ({}) => {

    const [savedItems, setSavedItems] = useState(null);

    // console.log ('current node (#40vmr99sdf): ', node)
    console.log ('savedItems: (#23sdf): ', savedItems)

    GetSavedItemsByUserId(savedItems, setSavedItems)

    const SIlist = (savedItems) ? savedItems.SIlist : null;
    const includedlist = (savedItems) ? savedItems.included : null;         // includedList is a list of nodes from the "compound" JSONAPI request that get the 'target' nodes of the saved items.
    console.log('#342r5wsfw: ', SIlist)
    return (
        <CustomContainer>
            <Row>
                <Col className="col" lg={4}>
                    Name
                </Col>
                <Col className="col" lg={2} className="d-none d-lg-block">
                    Type
                </Col>
                <Col className="col" lg={2}>
                    Country / state
                </Col>
                <Col className="col" lg={1}>
                    Remove
                </Col>
                <Col className="col" lg={2} className="d-none d-lg-block">
                    Saved ago
                </Col>
            </Row>
            <DisplayList SIlist={SIlist} includedlist={includedlist} setSavedItems={setSavedItems} savedItems={savedItems}></DisplayList>
        </CustomContainer>
    )
}

const DisplayList = ({SIlist, includedlist, setSavedItems}) => {
    const axiosCall = useAxiosCall()
    console.log('#3453rfsdc: ', SIlist)

    var emptyListMsg = null

    // if items empty (i.e. not yet axios returned or no item - even when axios returned, then do this:)
    if (SIlist == null) {
        emptyListMsg = 'Loading items...'
    } else {
        if (!SIlist.length() > 0) {
            emptyListMsg = 'No items saved in Bucktlist'
        }
    }

    if (emptyListMsg) {
        console.log('#ad22cwec: ', SIlist)
        return (
            <Row>
                <Col className="col" lg={12}>
                    {emptyListMsg}
                </Col>
            </Row>
        )
    }
    console.log('setSavedItems (#sdf23)', setSavedItems)

    // a callback for if/when the JSONAPI returns success on delete request.
    const removeItemAndAlertSucess = (itemToRemove) => {
        console.log('item list (#345rwsdf)', itemToRemove)
        console.log('item to remove (#345rwsdf)', SIlist)
        console.log('setSavedItems (#345rwsdf)', setSavedItems)

        // create toaster alert
        const msg = 'Successfully removed item: "' + itemToRemove.attributes.title + '"'
        messageService.sendMessageAutoClose(msg, 'success');

        // console.log('SIList', SIlist)
        SIlist.removeItem(itemToRemove)
        setSavedItems({ "SIlist": SIlist, "included": includedlist })
    }

    const onFailure = () => {
        messageService.sendMessageAutoClose('Error: unable to remove this saved item.', 'error');
    }
    
    // delete the item and pass in a "on success" call back.
    const handleDeleteOnClick = (curNode) => {DeleteSavedItem(curNode, axiosCall, () => {removeItemAndAlertSucess(curNode)}, onFailure)}

    const list = SIlist.getList()
    // Object.entries(data).map(([k, v]) => { 
    
    return (
        <>
            {Object.entries(list).map(([k,curNode]) => {
                console.log ('node (#234): ', k, curNode)
                // const curNode = list[k]
                const drupal_id = curNode.relationships.field_item_target.data.id
                // const drupal_id = 999

                console.log ('#sdarg3: ', includedlist)

                // const curTargetNodeId = curNode.relationships.field_item_target.id
                const curTarget = includedlist.getNodeById(drupal_id)
                console.log('#34wrsvs: ', curTarget)

                return (
                    <Row key={k}>
                        <Col className="col" lg={4}>
                            <a href={getSlug(curTarget)}>{curNode.attributes.title}</a>
                        </Col>
                        <Col className="col" lg={2} className="d-none d-lg-block">
                            <TypeMarker node={curNode.relationships.field_item_target}></TypeMarker>
                        </Col>
                        <Col className="col" lg={2}>
                            {curNode.attributes.field_country}, {curNode.attributes.field_saveditem_state}
                        </Col>
                        <Col className="col" lg={1}>
                            <a href="#" onClick={() => handleDeleteOnClick(curNode)}>Remove</a>
                        </Col>
                        <Col className="col" lg={2} className="d-none d-lg-block">
                            {
                            moment(Date.parse(curNode.attributes.created)).fromNow()
                            }
                        </Col>
                    </Row>
                )
            })}
        </>
    )

}

export default SavedItemsList