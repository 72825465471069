import React from "react"

import { ItineraryTypeMark, ActivityTypeMark, LocationTypeMark, PhotographTypeMark } from "styles/GlobalStyles.js"
import getDrupalNodeTypeOrError from "../../functions/Drupal/getDrupalNodeTypeOrError"

// Displays a colored 'type marker' (a colored label with the text of what content "type" it is e.g. Itinerary, Activity etc)
const TypeMarker = ({node}) => {
    console.log('node (#dfvsdf): ', node)

    const type = getDrupalNodeTypeOrError (node)

    switch (type) {
        case 'location':
            return <LocationTypeMark>Location</LocationTypeMark>
            break;
        case 'itinerary':
            return <ItineraryTypeMark>Itinerary</ItineraryTypeMark>
            break;
        case 'activity':
            return <ActivityTypeMark>Activity</ActivityTypeMark>
            break;
        case 'photograph':
            return <PhotographTypeMark>Photograph</PhotographTypeMark>
            break;
        default:
            throw new Error('type: "' + type + '" is not recognised. Please create a case for it (#4sdfew).')
    }
}

export default TypeMarker