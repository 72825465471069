import getDrupalNodeTypeOrError from "../Drupal/getDrupalNodeTypeOrError"

// return a string like: "/activity/43" or "itinerary_highlights/101"
const getSlug = (node) => {
    console.log('#csef: ', node)
    const type = getDrupalNodeTypeOrError(node)
    const drupal_id = node.attributes.drupal_internal__nid

    var pathPrefix = type

    switch (type) {
        case 'itinerary':
            pathPrefix = 'itinerary_highlights'
            break
    }

    const postFix = (node.attributes.path.alias) ? node.attributes.path.alias : '/' + drupal_id

    return pathPrefix + postFix
} 

export default getSlug